import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: "image-1",
        title: 'Drenagem Linfática',
        PagTarget: '/drenagem-linfatica',
    },
    {
        image: 'image1',
        title: 'Massagem Relaxante',
        PagTarget: '/massagem-relaxante',
    },
    {
        image: 'image1',
        title: 'Pedras quentes',
        PagTarget: '/pedras-quentes',
    },
    {
        image: 'image1',
        title: 'Pós Operatória',
        PagTarget: '/pos-operatorio',
    },
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.PagTarget}>Saiba Mais</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={value.PagTarget}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;