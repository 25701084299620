import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/thumb-massagem-corporal.png";
import serviceImg2 from "../assets/images/service/massagem-corporal.png";
import WhatsappBtn from "../component/common/WhatsappBtn";


class MassagemRelaxante extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Massagem Relaxante" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{ backgroundImage: `url(${serviceImg})` }}
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">MASSAGEM RELAXANTE</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg2}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Nossa massagem relaxante é uma jornada suave em direção à serenidade interior. Começamos criando um ambiente acolhedor, onde os sentidos são envolvidos por aromas calmantes, iluminação suave e música tranquilizante. Cada sessão é personalizada para atender às suas necessidades individuais, começando com uma consulta para entender suas áreas de tensão e preocupações específicas.
                          </p>
                          <p>
                            Além do alívio físico da tensão muscular, a massagem relaxante oferece uma série de benefícios mentais e emocionais. Você sairá da sua sessão sentindo-se renovado, com a mente clara e o corpo revitalizado. 
                          </p>
                          <h4 className="title">Alguns benefícios</h4>
                          <ul className="liststyle">
                              <li><strong>Alívio da Tensão Muscular:</strong> Técnicas suaves soltam músculos tensos, aliviando dores e aumentando flexibilidade.</li>
                              <li><strong>Melhoria da Circulação Sanguínea:</strong> Aumenta fluxo sanguíneo, fornecendo mais oxigênio e nutrientes e removendo toxinas.</li>
                              <li><strong>Aumento do Bem-Estar Mental:</strong> Acalma mente, reduzindo fadiga mental e promovendo bem-estar.</li>
                              <li><strong>Melhoria da Qualidade do Sono:</strong> Promove sono mais profundo e reparador, combatendo insônia e melhorando qualidade do sono.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Durante a massagem, nossos terapeutas especializados aplicam uma variedade de técnicas suaves, incluindo deslizamentos suaves, amassamentos delicados e movimentos de alongamento suaves. Essas técnicas são projetadas para liberar a tensão muscular, promover a circulação sanguínea e induzir um estado profundo de relaxamento.
                          </p>
                          <h4 className="title">Nosso procedimento</h4>
                          <ul className="liststyle">
                            <li><strong>Ambiente de Relaxamento Total:</strong> Criamos um ambiente tranquilo com luz suave, aromas deliciosos e música relaxante.</li>
                            <li><strong>Consulta Personalizada e Escolha de Óleo:</strong> Nossa equipe personaliza sua experiência, selecionando o óleo essencial perfeito para você.</li>
                            <li><strong>Toque Mágico de Relaxamento:</strong> Desfrute de movimentos habilidosos que aliviam a tensão e restauram seu equilíbrio físico e mental.</li>
                            <li><strong>Renovação Completa:</strong> Ao final, relaxe e desfrute. Oferecemos água refrescante e dicas para prolongar seu bem-estar. Experimente a verdadeira arte da descontração!</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                            <video className="w-100" controls autoPlay muted>
                              <source src={require('../assets/images/service/massagem-relaxante.mp4')} type="video/mp4" />
                              Seu navegador não suporta o elemento de vídeo.
                            </video>
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <WhatsappBtn />

        <Footer />
      </React.Fragment>
    );
  }
}
export default MassagemRelaxante;
