import React, { Component } from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaInstagram />, link: "https://www.instagram.com/spa.ingridzuany" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
             
              <div className="col-lg-12">
                <div className="footer-right">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Navegação</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/massagem-relaxante">Massagem Relaxante</a>
                          </li>
                          <li>
                            <a href="/pedras-quentes">Pedras Quentes</a>
                          </li>
                          <li>
                            <a href="/pos-operatorio">Pós Operatória</a>
                          </li>
                          <li>
                            <a href="/drenagem-linfatica">Drenagem Linfática</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15356.448941985003!2d-47.8975633!3d-15.7980349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3aebfafaed51%3A0xc7220f411b171832!2sC%20A%20S%20A%20B%20O%20H%20O!5e0!3m2!1sen!2sbr!4v1716433180519!5m2!1sen!2sbr" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Diretors Autorais © 2024 SPA Ingrid Zuany.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
