import React, { Component } from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import StudioAgency from "./home/StudioAgency";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ServiceDetails from "./elements/ServiceDetails";
import MassagemRelaxante from "./elements/MassagemRelaxante";
import DrenagemLinfatica from "./elements/DrenagemLinfatica";
import PedrasQuentes from "./elements/PedrasQuentes";
import PosOperatorio from "./elements/PosOperatorio";
import Contact from "./elements/Contact";
import About from "./elements/About";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import WhatsappBtn from "../src/component/common/WhatsappBtn";



class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
              

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={StudioAgency}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={ServiceDetails}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/massagem-relaxante`}
              component={MassagemRelaxante}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/drenagem-linfatica`}
              component={DrenagemLinfatica}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/pos-operatorio`}
              component={PosOperatorio}
            />

<Route
              exact
              path={`${process.env.PUBLIC_URL}/pedras-quentes`}
              component={PedrasQuentes}
            />


            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
           <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />

          <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
