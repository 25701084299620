import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || SPA Ingrid Zuany </title>
                    <meta name="description" content="Experimente a sensação de relaxamento com nossas massagens terapêuticas no Seu Spa. Oferecemos uma variedade de tratamentos para rejuvenescer mente e corpo."/>
                    <meta name="keywords" content="massagens, spa, relaxamento, escalda pés, terapia, bem-estar"/>

                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
