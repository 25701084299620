import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/drenagem-service-01.png";
import serviceImg1 from "../assets/images/service/thumb_drenagem1.png";
import serviceImg2 from "../assets/images/service/service-02.png";
import WhatsappBtn from "../component/common/WhatsappBtn";


class DrenagemLinfatica extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Drenagem Linfática" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{ backgroundImage: `url(${serviceImg1})` }}
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">DRENAGEM LINFÁTICA</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                          A drenagem linfática é uma técnica de massagem suave que estimula o sistema linfático a funcionar de maneira mais eficiente. Este método ajuda a remover toxinas e resíduos do corpo, melhorando a circulação linfática e reduzindo o inchaço. É frequentemente utilizada para aliviar a retenção de líquidos, combater a celulite e acelerar a recuperação após cirurgias. Além de seus benefícios estéticos, a drenagem linfática também promove relaxamento e bem-estar geral.
                          </p>
                          <p>
                          É indicado para pessoas que buscam desintoxicação, alívio de desconfortos e melhorias no sistema imunológico. Agendar sessões regulares pode contribuir para uma sensação geral de leveza e revitalização do corpo.
                          </p>
                          <h4 className="title">Alguns benefícios</h4>
                          <ul className="liststyle">
                          <li><strong>Redução do inchaço:</strong> Diminui a retenção de líquidos.</li>
                          <li><strong>Desintoxicação:</strong> Elimina toxinas e resíduos do corpo.</li>
                          <li><strong>Melhora da circulação:</strong> Estimula a circulação linfática e sanguínea.</li>
                          <li><strong>Alívio da celulite:</strong> Reduz a aparência da celulite.</li>
                          <li><strong>Relaxamento:</strong> Promove relaxamento e bem-estar geral.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                          Além dos benefícios estéticos, a drenagem linfática também é indicada para auxiliar na recuperação pós-operatória, aliviar sintomas de condições como a síndrome pré-menstrual e fortalecer o sistema imunológico. É importante ressaltar que a drenagem linfática deve ser realizada por profissionais treinados e pode ser adaptada às necessidades individuais de cada cliente.
                          </p>
                          <h4 className="title">Nosso procedimento</h4>
                          <ul className="liststyle">
                              <li><strong>Preparação:</strong> Avaliação inicial e estímulo suave dos pontos-chave.</li>
                              <li><strong>Execução:</strong> Movimentos suaves ao longo dos vasos linfáticos para drenagem.</li>
                              <li><strong>Finalização:</strong> Conclusão com movimentos relaxantes e recomendações personalizadas.</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <video className="w-100" controls autoPlay muted>
                          <source src={require('../assets/images/service/drenagem-video-01.mp4')} type="video/mp4" />
                          Seu navegador não suporta o elemento de vídeo.
                          </video>
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <WhatsappBtn />
        <Footer />
      </React.Fragment>
    );
  }
}
export default DrenagemLinfatica;
