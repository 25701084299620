import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin,FiPhoneCall } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PortfolioList from "../blocks/PortfolioList";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: -15.7976845,
            lng: -47.8976381
            
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190"  >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-color">Nossos Contatos</h2>
                                    <h4 className="title">
                                        Entre em contato com nossa recepção para obter informações sobre agendamentos, esclarecer dúvidas, deixar sua opinião sobre nossos serviços e produtos, ou saber mais sobre seu pedido. </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 ">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiPhoneCall />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">WhatsApp</h4>
                                        <p><a href="tel:+5561991167280"> (61) 99116-7280</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:spa.ingridzuany@gmail.com">spa.ingridzuany@gmail.com</a></p>
                                    </div>
                                </div>
                                <a  className="link-overlay" href="mailto:spa.ingridzuany@gmail.com"></a>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Endereço</h4>
                                        <p>SGAS I SGAS 902 LT 74 ED ATHENAS <br /> Asa Sul, Brasília - DF</p>
                                    </div>
                                </div>
                                <a  className="link-overlay" href="https://maps.app.goo.gl/iaQqEx3QAgRTPX418"></a>
                               
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                <div className="portfolio-area ptb--120 ">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--15">
                    <h2 className="title theme-color">Clique na massagem desejada para agendar seu momento de relaxamento.</h2>
                  
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 text-center">
                    <a
                      className="rn-button-style--2 btn-solid"
                      href="/portfolio"
                    >
                      <span>Quero Agendar</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1653.7891277525985!2d-47.89763804105797!3d-15.797684454125552!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3aebfafaed51%3A0xc7220f411b171832!2sC%20A%20S%20A%20B%20O%20H%20O!5e0!3m2!1sen!2sbr!4v1715715181939!5m2!1sen!2sbr" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                     
                    </div>
                </div>
                {/* End Contact Map  */}
                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact