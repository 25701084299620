import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import serviceImg from "../assets/images/service/pedras1.png";
import serviceImg1 from "../assets/images/service/thumb-pedras.png"
import serviceImg2 from "../assets/images/service/service-02.png";
import WhatsappBtn from "../component/common/WhatsappBtn";

class PedrasQuentes extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Massagem Relaxante" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image"
          style={{ backgroundImage: `url(${serviceImg1})` }}
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">PEDRAS QUENTES</h2>
                  {/* <p>Fresh From The Press Discription</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src={serviceImg}
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                          A terapia com pedras quentes é uma prática antiga que remonta a milhares de anos, com origens em diversas culturas ao redor do mundo, incluindo as antigas civilizações chinesa, egípcia, indígena americana e havaiana. Essa técnica foi revitalizada e popularizada na era moderna como uma forma eficaz de relaxamento e alívio do estresse.             
                          </p>
                          <p>
                          Essa técnica milenar proporciona bem-estar físico e mental, sendo ideal para quem busca alívio do estresse e revitalização das energias.
                          </p>
                          <h4 className="title">Alguns benefícios</h4>
                          <ul className="liststyle">
                            <li><strong>Relaxamento Profundo:</strong> Relaxa os músculos tensos.</li>
                            <li><strong>Alívio de Dores:</strong> Reduz dores e rigidez muscular.</li>
                            <li><strong>Melhora da Circulação:</strong> Aumenta a circulação sanguínea.</li>
                            <li><strong>Redução do Estresse:</strong> Diminui estresse e ansiedade.</li>
                            <li><strong>Desintoxicação:</strong> Ajuda a eliminar toxinas do corpo.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                          O calor das pedras ajuda a relaxar os músculos, aumentando a circulação sanguínea e promovendo a liberação de tensões acumuladas. A combinação do calor com a pressão suave da massagem ajuda a aliviar dores musculares, reduzir o estresse e promover uma sensação geral de bem-estar.
                          </p>
                          <h4 className="title">Nosso procedimento</h4>
                          <ul className="liststyle">
                            <li><b>Preparação das Pedras:</b> Aquecimento das pedras de basalto em água quente.</li>
                            <li><b>Posicionamento:</b> Colocação das pedras aquecidas em pontos específicos do corpo.</li>
                            <li><b>Massagem:</b> Utilização das pedras aquecidas para massagem relaxante.</li>
                            <li><b>Finalização:</b> Remoção das pedras e relaxamento do cliente.</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                            <video className="w-100" controls autoPlay muted>
                              <source src={require('../assets/images/service/pedras-quentes.mp4')} type="video/mp4" />
                              Seu navegador não suporta o elemento de vídeo.
                            </video>
                          {/* <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <WhatsappBtn />

        <Footer />
      </React.Fragment>
    );
  }
}
export default PedrasQuentes;
