import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import ModalVideo from "react-modal-video";
import { videoTagString, VideoTag } from "react-video-tag";
import Helmet from "../component/common/Helmet";
import WhatsappBtn from "../component/common/WhatsappBtn";
import PortfolioList from "../component/HomeLayout/homeOne/PortfolioList";

import video from "../assets/images/service/spa_ingrid_zuany_video.mp4";
import bgImage from "../assets/images/bg/bg-image-24.jpg";

videoTagString({
  src: video,
  poster: bgImage,
});

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    title: "SPA Ingrid Zuany",
    description:
      "Bem-vindo ao Spa Ingrid Zuany, seu santuário de tranquilidade no coração de Brasília. Cada toque é uma jornada para o bem-estar total.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

class StudioAgency extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    const PostList = BlogContent.slice(0, 3);
    return (
      <Fragment>
        <Helmet pageTitle="Spa Ingrid Zuany - Massagens Relaxantes, pedras quentes, atendimento a domícilio" />
        {/* Start Header Area  */}
        <Header />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
              key={index}
              data-black-overlay="6"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className="title">{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="video-inner">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                     
                    </div>
                  </div>
              
                </div>
              </div>
              <div className="video-background">
                <VideoTag
                  autoPlay={`${true}`}
                  muted={`${true}`}
                  playsInline={`${true}`}
                  loop={`${true}`}
                  src={video}
                  poster={bgImage}
                />
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area   */}
        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 ">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--15">
                    <h2 className="title">Massagens</h2>
                    <p>
                    De uma Massagem Relaxante que derrete o estresse do dia a dia a uma Revigorante Bambuterapia, nossa gama de tratamentos oferece algo para todos os gostos.
                     
                    </p>
                    <p>
                    Buscando Modelar o Corpo? Nossa Massagem Modeladora é a escolha certa. Para Futuras Mamães, oferecemos uma Massagem Suave e Reconfortante. Descubra o Equilíbrio do Corpo e da Mente com a Massagem Tailandesa e a Ayurvédica
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 text-center">
                    <a
                      className="rn-button-style--2 btn-solid"
                      href="https://wa.me/5561991167280?text=Olá,%20gostaria%20de%20saber%20mais!"
                    >
                      <span>Quero Agendar</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
     */}

        <FooterTwo />
    
        <WhatsappBtn />
      

        {/* End Footer Area  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default StudioAgency;
